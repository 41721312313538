import { authOperations, authSelectors, hooks, mainSelectors } from 'state';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Container, Text } from 'components';
import { Header, Footer } from 'components/layout';
import { useEffect } from 'react';
import { getUserFromSubcookies } from 'utils';
import StyledPrivateRoute from './private-route-styled';
import BottomNavigationBar from '../../layout/BottomNavigationBar';
import { allowedRoles, appNames } from 'utils/constants';
import { IAllowedRoles } from '../../../utils/constants/interfaces';
export const PrivateRoute = () => {
  const { useAppSelector, useAppDispatch } = hooks
  const { isAuthenticatedSelector, selectAuth } = authSelectors
  const { getUser } = authOperations
  const isAuthenticated = useAppSelector(isAuthenticatedSelector)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const userCookie = getUserFromSubcookies()
  const getValidTodken = () => {
    const {
      access_token,
      expires_at,
      scope,
      token_type,
    } = userCookie
    if (
      access_token
      && scope
      && token_type
    ) {
      const token = {
        access_token,
        expires_at,
        scope,
        token_type
      }
      return token
    }
    return false
  }

  const { selectmain } = mainSelectors
  const { isFetching } = useAppSelector(selectAuth)
  const { appType } = useAppSelector(selectmain)
  const roles = userCookie?.profile.role || []
  const hasActiveSubscription = roles.includes(allowedRoles[appType as keyof IAllowedRoles])

  useEffect(() => {
    const token = userCookie && getValidTodken()
    const dataTrackingSessionId = userCookie && userCookie.session_id
    if (appType) {
      if (hasActiveSubscription) {
        dispatch(getUser({ token, dataTrackingSessionId }))
      } else {
        navigate('/login')
      }
    }
  }, [appType])

  return (isAuthenticated && !isFetching)
    ? (
      <StyledPrivateRoute expand className={"route"}>
        <Header />
        <Container className="body">
          <Outlet />
        </Container>
        <BottomNavigationBar />
        <Footer />
      </StyledPrivateRoute>
    )
    : <Text textStyle='h1'>Loading</Text>
}

export default PrivateRoute

