 import { Button, Container, DropdownInput, DropdownInputRefactorized , Spinner, Text, TextInput, TimeInput } from '../..'
import { getColor, getFrequencyFromCronExpression, getHourFromCronExpression, getOnDayFromCronExpression, getRepeatDayFromCronExpression, getSelectedValues, getStringFromCronExpression } from 'utils'
import { authSelectors, hooks, mainOperations, mainSelectors } from 'state'
import { useEffect, useState } from 'react'
import { BiPause, BiPlay } from 'react-icons/bi'
import { LoadingSpinner } from 'assets'
import { MdEdit } from 'react-icons/md'
import StyledWorkbookExport from './workbook-export-styled'
import { toPlaceholder, subjectPlaceholder, emailBodyPlaceholder, toLabel, subjectLabel, emailBodyLabel, dashboardLabel, filtersLabel, formatAsLabel, cancelLabel, sendLabel , timeZoneLabel, updateScheduleLabel, deleteLabel, createScheduleLabel, newScheduleReportLabel, selectDayLabel, emailFormatMessage, emailListExample, timeLabel, repeatByLabel } from 'utils/constants/index'
import { emailRegExp } from 'utils/constants/regExp'
import { IFiltersGroup, IInitialValues, IReport, ISavedFilter, IScheduledReport } from 'utils/constants/interfaces'
import { screenSizes } from '../../../utils/constants/media-queries'
import useScreenSize from '../../../utils/hooks/useScreenSize'

export const WorkbookExport = ({ onRequestClose, isSendScheduled , showReportList, setShowReportList, initialValues }: any) => {
  const [sNInitialValues, setSNInitialValues] = useState(initialValues)
  const { useAppSelector, useAppDispatch } = hooks
  const [ screenWidth ] = useScreenSize();
  const { selectAuth } = authSelectors
  const { selectmain } = mainSelectors
  const { getUserReports, updateScheduledReportStatus, deleteReport, sendReport, updateScheduleSendReportV2, scheduleSendReportV2, setMainContent } = mainOperations
  const { selectedWorkbook, userReports, selectedCategories, selectedWorkbookV2, savedWorkbookFilters, workbookElements, workbookFilters } = useAppSelector(selectmain)
  const { user } = useAppSelector(selectAuth)
  let attachments = workbookElements!.length > 0  && workbookElements?.map(({ elementDisplayName, workbookElementGuid }: any) => ({
    label: elementDisplayName,
    value: workbookElementGuid
  }))
  
  const isDashboardDropdownEnabled = attachments && attachments?.length > 1 ? false : true;
  const repeatByOptions = [{ label: 'Daily', value: 'daily' }, { label: 'Weekly', value: 'weekly' }, { label: 'Monthly', value: 'monthly' }]
  const timezones = [
    { label: 'Alaska Standard Time', value: 'Alaskan Standard Time' },
    { label: 'Hawaii Standard Time', value: 'Hawaiian Standard Time' },
    { label: 'Pacific Standard Time', value: 'Pacific Standard Time' },
    { label: 'Mountain Standard Time', value: 'Mountain Standard Time' },
    { label: 'Eastern Standard Time', value: 'Eastern Standard Time' },
    { label: 'Central Standard Time', value: 'Central Standard Time' }
  ]
  let defaultFilterList = savedWorkbookFilters?.filter(({ savedFilterName }: any )=> savedFilterName !== null) || []
    
  const currentValueOption = { label: 'Current Filters', value: null }
  let filterListOptions = [currentValueOption, ...defaultFilterList.map(({ savedFilterGuid, savedFilterName}:any)=>({label: savedFilterName, value: savedFilterGuid}))]
  const formats = [{ label: 'CSV', value: 'csv' }, { label: 'Excel', value: 'xlsx' }]
  const dispatch = useAppDispatch()
  const {dashBoardItems} = sNInitialValues
  const [defaultFilter, setControlFilter] = useState(currentValueOption)
  const [fileFormat, setFileFormat] = useState(formats[0])
  const [selectedTimezone, setSelectedTimezone] = useState(timezones[2])
  const [selectedRepeatByOption, setSelectedRepeatByOption] = useState(repeatByOptions[0])
  const [cronExpression, setCronExpression] = useState('0 22 * * *')
  const [hour, setHour] = useState('23:00')

  const [repeatDay, setRepeatDay] = useState('0')
  const [onDay, setOnDay] = useState('1')
  const [isFetching, setIsFetching] = useState(true)
  const [report, setReport] = useState()
  const [presetName, setPresetName] = useState('')
  const {recipients, subject, message, body} = sNInitialValues

  const canSendReport = subject && body && recipients && message.length === 0 && dashBoardItems.length !== 0
  const workbookReports = userReports?.filter(({ workbookGuid: reportWorkbookGuid }: any) => reportWorkbookGuid === selectedWorkbookV2?.workbookGuid)
  const verifyEmail = (email: string) => {
   return(emailRegExp.test(email))
  } 


  const setCheckedAttachents = (value:any, e: any)=>{
    const matchedAttachment = attachments && attachments?.find((at:any)=> at.label === value)
    
    dashBoardItems.find((item:any)=> item.label === value) ? setSNInitialValues((prev:IInitialValues)=>{
      const { dashBoardItems } = prev
      if(!e?.target?.checked){
        return {...prev, dashBoardItems: dashBoardItems.filter((r:any)=>r.label !== value)}
      }
      return {...prev, dashBoardItems}
    }) 
    :
    setSNInitialValues((prev:any)=>({...prev, dashBoardItems: [...dashBoardItems, matchedAttachment]}))   
  }
  
  const handleCancelClickEvent = ()=>{
    if(workbookReports.length && isSendScheduled){
      setShowReportList(true)
    }else{
      setShowReportList(false)
      onRequestClose()
      screenWidth<= screenSizes.tablet ? dispatch(setMainContent('send')) : dispatch(setMainContent('home'))
    }
    loadDefaults()
  }

  const handleRecipientsChangeEvent = ({ target: { value } }: any) => {
    const isEmailValid = verifyEmail(value)
    if(isEmailValid || value === ''){
      setSNInitialValues({
        ...sNInitialValues,
        recipients: value,
        message: ''
      })
    }else{
        setSNInitialValues({
          ...sNInitialValues,
          recipients: value,
          message: emailListExample
        })
    }
  }

  const getAttachmentsGuidsSelected = () => {
        return dashBoardItems.map(({value}:any)=>value)
  }

  const onSendReport = async () => {
    const { reportD, workbookElementGuidList, filtersGroup, } = getScheduledReportDetailsV2()
    const paramsv2 = {
      ...reportD,
      savedFilterGuid: defaultFilter.value || savedWorkbookFilters?.find(({isAutoSaving}:ISavedFilter)=>isAutoSaving)?.savedFilterGuid,
      workbookGuid: filtersGroup.workbookGuid,
      workbookElementGuidList
    }
    setIsFetching(true)
    await dispatch(sendReport(paramsv2))
    onRequestClose()
    loadDefaults()
    await dispatch(getUserReports(selectedWorkbookV2?.workbookGuid))
    setIsFetching(false)
    
  }
  const getScheduledReportDetailsV2: ()=>{reportD: IReport, scheduledReport: IScheduledReport, filtersGroup: IFiltersGroup,  workbookElementGuidList:any} = () => {
    const reportD : IReport = {
      emailBody: body,
      emailSubject: subject,
      recipients,
      fileFormat: fileFormat.value,
    }
    const scheduledReport: IScheduledReport = {
      emailBody: body,
      emailSubject: subject,
      recipients,
      fileFormat: fileFormat.value,
      cronExpression,
      timeZone: selectedTimezone.value,
      reportId: (report as any)?.reportId,
    }
    const filtersGroup: IFiltersGroup = {
      savedFilterGuid: defaultFilter.value!,
      savedFilterName: defaultFilter.value ? defaultFilter.label : presetName,
      isDefault: false,
      isAutoSaving: false,
      sigmaControls: selectedWorkbookV2?.sigmaControls,
      workbookGuid: selectedWorkbookV2?.workbookGuid,
      filters: getSelectedValues(workbookFilters!)
    }

    const workbookElementGuidList = getAttachmentsGuidsSelected()
    return {
      reportD,
      scheduledReport,
      filtersGroup,
      workbookElementGuidList,
    }
  }

  const onScheduleReport = async () => {
    const params = {
      "SendTitle": subject,
      "SendBody": body,
      recipients,
      fileFormat: fileFormat.value,
      cronExpression,
      "timeZone": selectedTimezone.value,
      "workbookGuid": selectedWorkbookV2?.workbookGuid,
      "workbookElementsGuids": getAttachmentsGuidsSelected(),
      "controlFilterGuid": defaultFilter.value,
      controlFilterParams: {
        ...selectedWorkbook?.variables,
        presetName: defaultFilter.value ? defaultFilter.label : presetName,
        categories: selectedCategories,
      }
    }

    setIsFetching(true)
    const filtersGroup: IFiltersGroup = {
      savedFilterGuid: defaultFilter.value!,
      savedFilterName: params.controlFilterParams.presetName,
      isDefault: false,
      isAutoSaving: false,
      sigmaControls: selectedWorkbookV2.sigmaControls,
      workbookGuid: selectedWorkbookV2.workbookGuid,
      filters: getSelectedValues(workbookFilters!)
    }
    const workbookElementGuidList = params.workbookElementsGuids
    const emailSubject = params.SendTitle
    const emailBody = params.SendBody
    const scheduledReport: IScheduledReport = {
      emailBody,
      emailSubject,
      recipients,
      cronExpression,
      fileFormat: fileFormat.value,
      timeZone: selectedTimezone.value
    }
    delete params.workbookElementsGuids
    delete params.SendBody
    delete params.SendTitle
    await dispatch(scheduleSendReportV2({scheduledReport,filtersGroup, workbookElementGuidList }))
    await dispatch(getUserReports(selectedWorkbookV2?.workbookGuid))
    setIsFetching(false)
    loadDefaults()
  }

  const onUpdateScheduleReport = async () => {
    const params = {
      reportId: (report as any).id,
      "SendTitle": subject,
      "SendBody": body,
      recipients,
      fileFormat: fileFormat.value,
      cronExpression,
      "timeZone": selectedTimezone.value,
      "workbookGuid": (report as any).workbookGuid,
      "workbookElementsGuids": getAttachmentsGuidsSelected(),
      "controlFilterGuid": defaultFilter.value,
      controlFilterParams: {
        ...selectedWorkbook?.variables,
        presetName: defaultFilter.value ? defaultFilter.label : presetName,
        categories: selectedCategories,
      }
    }
    setIsFetching(true)
    const {scheduledReport, filtersGroup, workbookElementGuidList } = getScheduledReportDetailsV2()
    await dispatch(updateScheduleSendReportV2({scheduledReport, filtersGroup, workbookElementGuidList }))
    await dispatch(getUserReports(selectedWorkbookV2?.workbookGuid))
    setIsFetching(false)
    loadDefaults()
  }


  const onDeleteReport = async () => {
    setIsFetching(true)
    const reportId =  (report as any)?.reportId
    await dispatch(deleteReport(reportId))
    await dispatch(getUserReports(selectedWorkbookV2?.workbookGuid))
    setShowReportList(true)
    setIsFetching(false)
    loadDefaults()
    onRequestClose()
  }

  const loadDefaults = () => {
    if (report) {
      setReport(undefined)
    }
    setSelectedRepeatByOption(repeatByOptions[0])
    setFileFormat(formats[0])
    setCronExpression('0 22 * * *')
    setHour('23:00')
    setRepeatDay('0')
    setOnDay('1')
    setPresetName('')
    setSelectedTimezone(timezones[2])
    setSNInitialValues(initialValues)
  }

  const onUpdateScheduleReportStatus = async (report: any) => {
    const params = {
      reportId: report.reportId,
      status: report.isEnabled ? 'inactive' : 'active'
    }
    setIsFetching(true)
    await dispatch(updateScheduledReportStatus(params))
    await dispatch(getUserReports(selectedWorkbookV2?.workbookGuid))

    setIsFetching(false)
  }

  const onEditCurrent = (report: any) => {
    const selectedFilter = filterListOptions.find(({ value }: any) => value === report.savedFilterGuid) 
    const workbooks = report.workbookElementGuidList.filter((w:string)=>w!== '')
    const reportSelectedAttachment = attachments && attachments.filter((a: any)=> workbooks.includes(a.value))
    const { index }: any = getFrequencyFromCronExpression(report.cronExpression)
    setReport(report)
    setSNInitialValues({
      ...sNInitialValues,
      body: report.sendBody || report.emailBody,
      recipients: report.recipients,
      subject: report.sendTitle || report.emailSubject,
      dashBoardItems: reportSelectedAttachment 
    })
    setControlFilter(selectedFilter || currentValueOption)
    setFileFormat(report.fileFormat === 'Csv' ? formats[0] : formats[1])
    setCronExpression(report.cronExpression)
    setShowReportList(false)
    setSelectedRepeatByOption(repeatByOptions[index])
    setSelectedTimezone({ label: report.timeZone, value: report.timeZone })
    setHour(getHourFromCronExpression(report.cronExpression))

    if (repeatByOptions[index].value === 'weekly' && report.cronExpression) {
      setRepeatDay(getRepeatDayFromCronExpression(report.cronExpression))
    }
    if (repeatByOptions[index].value === 'monthly' && report.cronExpression) {
      setOnDay(getOnDayFromCronExpression(report.cronExpression))
    }
  }

  useEffect(() => {
    let minutes = hour.split(':')[1]
    let splittedHour = Number(hour.split(':')[0])
    let hours = splittedHour >= 10 ? splittedHour : `0${splittedHour}`
    switch (selectedRepeatByOption.value) {
      case 'daily':
        setCronExpression(`${Number(minutes) || 0} ${hours || 0} * * *`)
        break;
      case 'weekly':
        setCronExpression(`${Number(minutes)} ${hours} * * ${repeatDay}`)
        break;
      case 'monthly':
        setCronExpression(`${Number(minutes)} ${hours} ${onDay} * *`)
        break;
      default:
        break;
    }
  }, [selectedRepeatByOption, hour, onDay, repeatDay])

  useEffect(() => {
    if (userReports) {
      if ((userReports.length > 0)) {
        setShowReportList(true)
      } else {
        setShowReportList(false)
      }
      setIsFetching(false)
    }
    if(initialValues){
      setSNInitialValues(initialValues)
      setControlFilter(currentValueOption)
      setFileFormat(formats[0])
    }
  }, [userReports,initialValues])

  useEffect(() => {
    if (!userReports) {
      dispatch(getUserReports(selectedWorkbookV2?.workbookGuid))     
    }
  }, [])

  if (showReportList === undefined) {
    return <Spinner text='Loading' size='24px' color='brand'><LoadingSpinner/></Spinner>
  }

  if (showReportList && isSendScheduled) {
    const renderReportRow = (report: any) => {
      const { id, isEnabled, sendTitle, emailSubject, cronExpression: reportCronExpression } = report
      return (
        <Container key={`report_${id}`} className="report">
          <Text>
            {sendTitle || emailSubject}
          </Text>
          <Text>
            {getStringFromCronExpression(reportCronExpression)}
          </Text>
          {
            isEnabled
              ? <Container className={'status'}>
                <Container className={'active indicator'}></Container>
                <Text>Active</Text>
              </Container>
              : <Container className={'status'}>
                <Container className={'inactive indicator'}></Container>
                <Text>Inactive</Text>
              </Container>
          }
          <Container className={'report-actions'}>
            <Container className={'report-action-button'} onClick={() => onUpdateScheduleReportStatus(report)}>
              {isEnabled ? <BiPause /> : <BiPlay />}
            </Container>
            <Container className={'report-action-button'} onClick={() => onEditCurrent(report)}>
              <MdEdit />
            </Container>
          </Container>
        </Container>
      )
    }
    if (workbookReports?.length > 0) {
      return (
        <StyledWorkbookExport expand className={'workbook-exports'}>
          <Container expand className={'row'}>
            <Container className={'report-list'}>
              {workbookReports.map(renderReportRow)}
            </Container>
          </Container>
          <Container className={'actions-section'}>
            <Container className={'action-buttons'}>
              <Button
                onClick={() => {
                  loadDefaults()
                  setShowReportList(false)
                }}
              >
                <Text>
                  {newScheduleReportLabel}
                </Text>
              </Button>
            </Container>
          </Container>
        </StyledWorkbookExport>
      )
    }
  }

  return (
    <StyledWorkbookExport expand className={'workbook-exports'}>
      <Container expand className={'row'}>
        <TextInput
          color={`brandDarkHight`}
          value={recipients}
          onChange={handleRecipientsChangeEvent}
          label={toLabel}
          placeholder={toPlaceholder}
        />
        <TextInput
          color={`brandDarkHight`}
          value={subject}
          onChange={({ target: { value } }: any) => setSNInitialValues({
            ...sNInitialValues,
            subject: value
          })}
          label={subjectLabel}
          placeholder={subjectPlaceholder}
          max={'255'}
        />
      </Container>

      {
        message.length > 0 &&
            <Container className={'row-container message'}>
              <Text color="brandDarkHight">
                {emailFormatMessage}
              </Text>
              <ul><li style={{color: getColor('brandDark')()}}><Text color='brandDark'>{message}</Text></li></ul>
            </Container>
      }

      <Container expand className={'row'}>
        <TextInput
          color={`brandDarkHight`}
          multiline={7}
          value={body}
          onChange={(value: any) => setSNInitialValues({
            ...sNInitialValues,
            body: value
          })}
          label={emailBodyLabel}
          placeholder={emailBodyPlaceholder}
        />
      </Container>
      <Container expand className={'row'}>
        <Container className={'dropdown-input'}>
          <Container className={'input-label'}>
            <Text textStyle='h4' color='gray1'>{dashboardLabel}</Text>
          </Container>
          <div
          className={`dropdown-input-wrapper`}
          style={{
            display: 'flex',
            width:  '100%',
            height: '100%',
          }}>
          <DropdownInputRefactorized
            allowMultipleSelection={true}
            disabled={isDashboardDropdownEnabled}
            label={dashBoardItems?.map((at:any)=> at?.label).join(',')}
            options={attachments && attachments.map((cat: any) => ({
              key: cat.label,
              label: cat.label
            }))}
            onChange={(values: any, e:any) => {setCheckedAttachents(values,e);}}
          />
          </div>
        </Container>

        <Container className={'dropdown-input'}>
          <Container className={'input-label'}>
            <Text textStyle='h4' color='gray1'>{filtersLabel}</Text>
          </Container>
          {defaultFilter && <DropdownInput
            label={defaultFilter.label}
            options={filterListOptions}
            onChange={(filterSelected: any) => filterSelected && setControlFilter(filterSelected)}
            width='190px'
            isDropdownActionsActive={false}
          />}
        </Container>

        <Container className={'dropdown-input'}>
          <Container className={'input-label'}>
            <Text textStyle='h4' color='gray1'>{formatAsLabel}</Text>
          </Container>
          <DropdownInput
            clearStyles
            label={fileFormat.label}
            options={formats}
            onChange={(values: any) => values && setFileFormat(values)}
          />
        </Container>
      </Container>
      { isSendScheduled && !defaultFilter.value && <div style={{width:'100%'}}>
        <Container className={'row'}>
          <TextInput
            color={'brandDarkHight'}
            value={presetName}
            onChange={({ target: { value } }) => setPresetName(value)}
            label={'Filter Name'}
            placeholder='E.g. "California Edibles"'
          />
        </Container>
      </div>
      }

      {
        isSendScheduled &&
        <>
          <Container expand className={'row'}>
            <Container className={'dropdown-input'}>
              <Container className={'input-label'}>
                <Text textStyle='h4' color='gray1'>{repeatByLabel}</Text>
              </Container>
              <DropdownInput
                clearStyles
                label={selectedRepeatByOption.label}
                options={repeatByOptions}
                onChange={(values: any) => values && setSelectedRepeatByOption(values)}
              />
            </Container>

            <Container className={'dropdown-input'}>
              <Container className={'input-label'}>
                <Text textStyle='h4' color='gray1'>{timeZoneLabel}</Text>
              </Container>
              <DropdownInput
                clearStyles
                label={selectedTimezone.label}
                options={timezones}
                onChange={(values: any) => values && setSelectedTimezone(values)}
              />
            </Container>
            {
              selectedRepeatByOption.value === 'daily' && <TimeInput
                value={hour}
                onChange={({ target: { value } }: any) => setHour(value)}
                label={timeLabel}
              />
            }
          </Container>
          {
            selectedRepeatByOption.value === 'weekly' && (
              <Container expand className={'row'}>

                <TimeInput
                  color={'gray1'}
                  onChange={(value: any) => setRepeatDay(value)}
                  initialValue={`${(report as any)?.cronExpression ? getRepeatDayFromCronExpression((report as any).cronExpression) : 0}`}
                  label={selectDayLabel}
                  type='day'
                />

                <TimeInput
                  color={`gray1`}
                  value={hour}
                  onChange={({ target: { value } }: any) => setHour(value)}
                  label="Time"
                />
              </Container>
            )
          }
          {
            selectedRepeatByOption.value === 'monthly' && (
              <Container expand className={'row'}>

                <TextInput
                  color={`text`}
                  onChange={({ target: { value } }: any) => setOnDay(value)}
                  label="On Day"
                  value={onDay}
                  type='number'
                  min='1'
                  max='29'
                />

                <TimeInput
                  color={`text`}
                  value={hour}
                  onChange={({ target: { value } }: any) => setHour(value)}
                  label="Time"
                />

              </Container>
            )
          }
        </>
      }
      <Container className={'actions-section'}>
        <Container className={'action-buttons'}>
          <Button
            onClick={handleCancelClickEvent}
            disabled={isFetching}
          >
            <Text>
              {cancelLabel}
            </Text>
          </Button>
          {report && <Button
            className={'delete'}
            onClick={onDeleteReport}
            disabled={isFetching}
          >
            <Text>
              {deleteLabel}
            </Text>
          </Button>}
          {isSendScheduled
            ? <Button
              onClick={report ? onUpdateScheduleReport : onScheduleReport}
              disabled={!canSendReport || (!presetName && !defaultFilter.value)}
            >
              {      
                isFetching
                  ? <LoadingSpinner />
                  : report
                    ? <Text>
                      {screenWidth <= screenSizes.tablet ? 'UPDATE' : updateScheduleLabel}
                    </Text>
                    : <Text>
                      {screenWidth <= screenSizes.tablet ? 'SCHEDULE' : createScheduleLabel}
                    </Text>
              }
            </Button>
            : <Button
              onClick={onSendReport}
              disabled={!canSendReport}
            >
              <Text>
                {sendLabel}
              </Text>
            </Button>
          }
        </Container>
      </Container>
    </StyledWorkbookExport>
  )
}

export default WorkbookExport