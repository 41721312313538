import './rootStyles.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { Container, PrivateRoute, SnackManager, Text } from './components'
import { authSelectors, dataTrackingOperations, hooks, mainOperations, store } from "./state";
import { Terms } from './pages'
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useCallback, useEffect } from 'react';
import { getUserFromSubcookies } from 'utils/tools';
import { migrateCookies } from 'utils/cookieMigration';
import Sidebar from './components/layout/Sidebar/index'
import StyledApp from './App-styled';
import { appNames } from 'utils/constants'
import ParentComponent from './ParentComponent';
import Download from './components/layout/Download';

const App = () => {
  migrateCookies();
  const state = store.getState()
  const { isSidebarOpen } = state.main
  const userDataFromCookie = getUserFromSubcookies();
  const { useAppDispatch, useAppSelector } = hooks
  const { setAppType, setSidebarOpen } = mainOperations
  const { trackingInit, trackEvent } = dataTrackingOperations
  const { selectAuth } = authSelectors
  const { user, dataTrackingSessionId } = useAppSelector(selectAuth)
  const dispatch = useAppDispatch()
  const location = window.location;
  const shouldRenderAppTypeSelector = location.host === "localhost:3000";
  const shouldRenderEnvWaterMark = process.env["REACT_APP_ENVIRONMENT"] === 'dev' || process.env["REACT_APP_ENVIRONMENT"] === 'staging'

  /*
    There is no other appType so far beside Insights
    import { mainSelectors } from "./state"
    const { selectmain } = mainSelectors
    const { appType } = useAppSelector(selectmain)
  */
  const getAppType = useCallback(() => appNames.insights, [])

  const initialiceApp = useCallback(() => {
    const appType = getAppType()
    dispatch(setAppType(appType))
  }, [dispatch, getAppType, setAppType])

  const onChangeAppType = (e: any) => {
    dispatch(setAppType(e.target.value))
  }

  useEffect(() => {
    initialiceApp()
  }, [initialiceApp])

  useEffect(() => {
    if (user) {
      dispatch(trackingInit())
    }
  }, [user])

  useEffect(() => {
    if (dataTrackingSessionId) {
      dispatch(trackEvent({ eventCategory: 'land', values: true, event: 'load_app' }))
    }
  }, [dataTrackingSessionId])

  const trackEventSwitchOnSessionTimeout = async () => {
    dispatch(trackEvent({ eventCategory: 'switch', values: JSON.stringify({ targetApp: 'PAM' }), event: 'switch_on_session_timeout' }))
    window.location.assign(`${process.env.REACT_APP_LANDING_URL}/time-out`)
  }

  const trackEventSwitchOnNoSession = async () => {
    await dispatch(trackEvent({ eventCategory: 'switch', values: JSON.stringify({ targetApp: 'PAM' }), event: 'switch_on_no_session' }))
    window.location.assign(`${process.env.REACT_APP_LANDING_URL}/${getAppType()}`)
  }

  if (!userDataFromCookie ) {
    // if(!location.pathname.includes('download')){
      trackEventSwitchOnNoSession()
      return <></>
    // }
  }

  return (
    <>
      <StyledApp expand className="main">
        {
          shouldRenderEnvWaterMark
          && (
            <Container className="environment-indicator">
              <Text textStyle='support' color='grayClear' textAlign='right'>{`${process.env["REACT_APP_ENVIRONMENT"]}`}</Text>
            </Container>
          )
        }
        <Routes>
          <Route path='/' element={<PrivateRoute />} >
            <Route index element={<ParentComponent />} />
            <Route path="/:category/:id" element={<ParentComponent />} />
          </Route>
          {/* <Route path='/download/:token' element={<Download/>}/> */}
          <Route path='/terms' element={<Outlet />}>
            <Route index element={<Terms />} />
            <Route path="*" element={<Navigate to="/terms" />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <SnackManager />
        <Container className={`${isSidebarOpen ? 'sidebar-overlay' : ' sidebar-overlay-container disabled'}`} onClick={() => dispatch(setSidebarOpen(!isSidebarOpen))}>
        </Container>
        <Container className={`${'sidebar-section-container'} ${isSidebarOpen ? ' expanded' : ' colapsed'}`} >
          <Sidebar />
        </Container>
      </StyledApp>
    </>
  );
}

export default App;
