import { useEffect, useState } from 'react'
import { authSelectors, mainSelectors } from '../../../state/ducks'
import { hooks } from '../../../state/utils'
import { IStack } from 'utils/constants/interfaces'
import { Snack } from '.'
import {v4 as uuid} from 'uuid'
import { StyledSnackManager } from './snack-manager-styled'

export const SnackManager = () => {
  const { useAppSelector } = hooks
  const { selectAuth } = authSelectors
  const { selectmain } = mainSelectors
  const { message: mainMessage } = useAppSelector(selectmain)
  const { message: authMessage } = useAppSelector(selectAuth)
  const [stack, setStack] = useState([] as IStack[])

  const onSnackClose = async (key:string) => {
    const index = stack.findIndex((sn:any) => sn.key === key)
    setStack((prev)=>{
      return [...prev].toSpliced(index, 1, {...prev[index], visible: false})
    })
  }
  
  useEffect(() => {
    if (authMessage) {
      if (Array.isArray(stack)) {
        const snackKey = authMessage?.key || uuid()
        setStack((prev)=>{
          return ([...prev, {
            ...authMessage,
            key: snackKey,
            visible: true
          }])
        })
      }
    }
  }, [authMessage])
  
  useEffect(() => {
    if (mainMessage) {
      if (Array.isArray(stack)) {
        const snackKey = mainMessage?.key || uuid()
        setStack((prev)=>{
          return ([...prev, {
          ...mainMessage,
          key: snackKey,
          visible: true
        }])})
      }
      if (mainMessage.action && mainMessage.action?.actionType === 'stopActivityIndicator') {
        onSnackClose(mainMessage.action.targetKey)
      }
    }
  }, [mainMessage])

  return (
    <StyledSnackManager className="SnackManager">
      {
        stack?.map((snack:IStack) => (
          <Snack key={snack.key} data={snack} onClose={() => onSnackClose(snack.key)}/>
        ) )
      }
    </StyledSnackManager>
  )
}

export default SnackManager