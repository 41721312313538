import { Container } from '../../..'
import { mainOperations, mainSelectors } from '../../../../state/ducks'
import { hooks } from '../../../../state/utils'
import { LoadingSpinner } from 'assets'
import StyledWorkbookDownload from './workbook-download-styled'
import WorkBookElementsList from 'components/layout/dashboard/workbook-download/workbook-elements-list'
import { Spinner } from '../../../shared/ui-elements/spinner'

export const WorkbookDownload = () => {
  const { useAppSelector, useAppDispatch } = hooks
  const { selectmain } = mainSelectors
  const { downloadWorkbook } = mainOperations
  
  const { workbookElements, selectedWorkbookV2 } = useAppSelector(selectmain)
  const dispatch = useAppDispatch()
  const download = (workbookElementGuid:any, format:any, fileName:any, elementDisplayName: string | undefined) =>{
    if (workbookElementGuid === 'all') {
      fileName.map((file:any) => {
        const id = file.workbookElementGuid
        const name = file.elementDisplayName
        dispatch(downloadWorkbook({ workbookElementGuid: id, format, fileName:  `${selectedWorkbookV2.workbookDisplayName} - ${name}` }))
      })
    } else {
      if(workbookElements?.length! > 1){
        dispatch(downloadWorkbook({workbookElementGuid, format, fileName: `${fileName} - ${elementDisplayName}`}))
      }else{
        dispatch(downloadWorkbook({workbookElementGuid, format, fileName}))
      }
    }
  }

  const downloadList = (
    <Container className={"download-list"}>
      {
        <WorkBookElementsList workbookElements={workbookElements!} download={download} /> 
      }
    </Container>
  )

  return (
    <StyledWorkbookDownload className={`workbook-download`}>
      {
        (workbookElements?.length! > 0 ? downloadList : <Spinner text='Loading...' size='24px' color='brand'><LoadingSpinner/></Spinner>)
      }
    </StyledWorkbookDownload>
  )
}
export default WorkbookDownload