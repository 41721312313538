import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container } from '../../containers'
import { BiSearch } from 'react-icons/bi'
import SortIcon from './sort-icon'
import { getColor } from '../../../../utils'
import { Button, Text } from 'components'
import { SortInterface } from './interfaces'
import { IoClose } from 'react-icons/io5'
import StyledDropdownActions from './dropdown-actions-styled'
import { IDropDownActionsInputs } from './interfaces'
import SortOptionDropdown from './sort-option-drowpdown'
import StyledSortOptions from './sorting-options'
export default function DropdownActions({filterSearchedValueInList, resetFilteredValues, setSearchedText, searchedText, options, handleOptions, sortBy, setSortBy }:IDropDownActionsInputs) {
    const [sortByOption, setSortByOption] = useState(sortBy)
    
    useEffect(()=>{
        setSortByOption(sortBy)
        showIcon(<SortOptionDropdown type={sortBy!} onClickHandler={changeSortOptionState}/>)
    },[sortBy])

    const changeSortOptionState = ()=>{
        setIsSortOptionsOpen(prev=>!prev)
    }

    const onPointerLeaveHandler = () =>{
        setIsSortOptionsOpen(false)
    }

    const onClickHandler = (sortingOptionSelected:string)=>{
        if(sortingOptionSelected === 'ASC'){
            handleOptions([...options].sort((a:{label:string,id:string},b:{label:string,id:string})=>{
                const labelA =  a.label.toUpperCase()
                const labelB =  b.label.toUpperCase()
                if ( labelA > labelB ){
                    return 1;
                    }
                    if ( labelA < labelB ){
                    return -1;
                    }
                    return 0;
            }))
            showIcon(<SortOptionDropdown type={'ASC'} onClickHandler={changeSortOptionState}/>)
            setSortByOption('ASC')
            setSortBy && setSortBy('ASC')
        }else if(sortingOptionSelected === 'DESC'){
            handleOptions([...options].sort((a:{label:string,id:string},b:{label:string,id:string})=>{
                const labelA =  a.label.toUpperCase()
                const labelB =  b.label.toUpperCase()

                if ( labelA < labelB ){
                    return 1;
                }
                if ( labelA > labelB ){
                    return -1;
                }
                return 0;
            }))
            showIcon(<SortOptionDropdown type={'DESC'} onClickHandler={changeSortOptionState}/>)
            setSortByOption('DESC')
            setSortBy && setSortBy('DESC')
        }
        changeSortOptionState()
    }
    const [isSortOptionsOpen, setIsSortOptionsOpen] = useState(false)
    const [iconToRender, setIconToRender] = useState<React.ReactElement | null>(<SortOptionDropdown type={sortByOption!} onClickHandler={changeSortOptionState}/>)

    const showIcon = (icon: React.ReactElement) =>{
        const component = icon
        setIconToRender(component)
    }
    useEffect(()=>{

    },[options])

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void =>{
        setSearchedText(event.target.value)
        filterSearchedValueInList(event.target.value)
    }
        
    return (
        <StyledDropdownActions className={'dropdown-actions'}>
            <div className='loader'>
                <div></div>
            </div>
            <Container className={'dropdown-action-search'}>
                <Container className='search-icon'>
                    <BiSearch color={getColor('brand')()}/>
                </Container>
                <input placeholder='Search' onChange={onChangeHandler} value={searchedText}></input>
            </Container>
            <Button className={`x-icon ${searchedText.length >= 1 ? 'visible' : 'hidden'}`} onClick={()=>{resetFilteredValues();setSearchedText('')}}>
                    <IoClose/>
            </Button>
            {iconToRender}
            {<Container className={`sort-by-container${isSortOptionsOpen ? ' visible' : ' hidden'}`} onMouseLeave={(event)=>{onPointerLeaveHandler();}}>
                <p className={'sort-by-title'}>Sort by</p>
                <StyledSortOptions sortBy={sortByOption}>
                    <Container className={`sort-by-option-container${sortByOption === 'ASC' ? ' default': ' '}`} onClick={(event)=>{onClickHandler('ASC');}}>
                        <SortIcon isAscending isAlphaNumeric width='20' marginRight="8"/>
                        <p>Ascending by alpha-numeric</p>
                    </Container>
                    <Container className={`sort-by-option-container${sortByOption === 'DESC' ? ' default': ' '}`} onClick={(event)=>{onClickHandler('DESC');}}>
                        <SortIcon isAlphaNumeric width='20' marginRight="8"/>
                        <p>Descending by alpha-numeric</p>
                    </Container>
                </StyledSortOptions>
            </Container>}
        </StyledDropdownActions>
    )
}
