import { dataTrackingOperations, store } from "state";
import { getUserFromSubcookies } from "utils";
import axios from 'axios';


const getHeaders = () => ({
  "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/x-www-form-urlencoded",
  "Accept": '*/*',
  "Accept-Econding": "gzip, deflate, br",
  "App-Origin": 'insights'
})

const checkToken = async () => {
  const { dispatch } = store;
  const { trackEvent } = dataTrackingOperations;
  const userCookie = getUserFromSubcookies()
  const isStillAlive = userCookie?.cookie_expiration_date > new Date().getTime()
  const lastActivityTime = store.getState().main.lastActivityTime
  const tokenExpiresAt = userCookie?.expires_at * 1000  

  if (!isStillAlive) {
    await dispatch(trackEvent({ eventCategory: 'switch', values: JSON.stringify({ targetApp: 'PAM' }), event: 'switch_on_timeout' }))
    if (lastActivityTime) {
      window.location.assign(`${process.env.REACT_APP_LANDING_URL}/time-out`)
    } else {
      window.location.assign(`${process.env.REACT_APP_LANDING_URL}/time-out?action=showLogin`)
    }
  }

  if (tokenExpiresAt && tokenExpiresAt < new Date().getTime()) {
    await dispatch(trackEvent({ eventCategory: 'token', values: JSON.stringify({ path: window.location.href }), event: 'token_expired' }))
    await dispatch(trackEvent({ eventCategory: 'switch', values: JSON.stringify({ targetApp: 'PAM' }), event: 'switch_on_token_expire' }))
    window.location.assign(`${process.env.REACT_APP_LANDING_URL}/refresh-token?redirectUrl=${window.location.href}`)
  }
  return true
}

const apiV2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_V2}`,
  // headers: {
  //   "Content-Type": "application/json",
  // }
})
apiV2.defaults.headers.common['Origin'] = 'dev'

apiV2.interceptors.request.use(
  (config) => {
   const { token } = store.getState().auth;
   if(!config?.headers){
     throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
   }
   config.headers['content-type'] = 'application/json'
   config.headers.Authorization = `${token?.token_type} ${token?.access_token}`
   config.headers['App-Origin'] = 'insights'
   return config
  }
 );

const apiUtils = {
  getHeaders,
  checkToken,
  apiV2
}
export default apiUtils