import { Button, CheckboxInput, Container, Modal, Text, TextInput } from 'components'
import { Fragment, useEffect, useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { hooks, mainOperations, mainSelectors } from 'state'
import { getColor, getSelectedValues } from 'utils'
import { cancelLabel, confirmationButtonLabel, confirmationMessage, confirmationQuestionMessage, defaultFilterLabel, deleteFilterLabel, filterDetailsLabel, filterNameDuplicatedMessage, filterNameIsBlankMessage, filterNameLabel, filtersSavedLimitMessage, goBackLabel, makeDefaultFilterLabel, saveLabel } from 'utils/constants/labels'
import { ConfirmationTitle } from '../../../utils/constants'
import { IFilter, IFilterLabel, IFilterValueSelected, IFiltersGroup, ISavedFilter, ISelectedFilters, ISigmaControl } from '../../../utils/constants/interfaces'
import StyledWorkbookFilterSave from './workbook-preset-save-styled'

export const WorkbookFilterSave = ({ onSave: propsOnSave, selectedFilter, isEditFilterClicked, setIsEditFilterClicked}: any) => {
  const { selectmain } = mainSelectors
  const { useAppSelector, useAppDispatch } = hooks
  const { selectedWorkbook, selectedWorkbookV2, userReports, workbookFiltersLabels, workbookFilters , savedWorkbookFilters} = useAppSelector(selectmain)
  const { setMainContent, saveWorkbookFilters, deleteControlFilterV2} = mainOperations
  const [filterName, setFilterName] = useState('')
  const [isFilterNameDuplicated, setIsFilterNameDuplicated] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [filterListV2, setFilterListV2] = useState([] as ISavedFilter[]) 
  const [isDefault, setIsDefault] = useState<any>()
  const [visibility, setVisibility] = useState('hidden')
  const [selectedOption, setSelectedOption] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useAppDispatch()
  const isFilterListLimitReached = false

  useEffect(() => {
    if (selectedOption === 'yes') {
      updateControlFilter()
    }
  }, [selectedOption])

  useEffect(() => {
  }, [selectedWorkbook,userReports])

  useEffect(()=>{
    if(savedWorkbookFilters!.length > 0){
      const newSavedFilterlist = [...savedWorkbookFilters!]
      if(newSavedFilterlist.length > 0){
        setFilterListV2(newSavedFilterlist!)
      }
    }
  }, savedWorkbookFilters)

  useEffect(() => {
    if (selectedFilter) {
      setFilterName(selectedFilter.savedFilterName)
      setIsDefault(selectedFilter.savedFilterGuid === selectedWorkbookV2?.defaultedTo?.savedFilterGuid)
      setIsFilterNameDuplicated(false)
    }
  }, [selectedFilter])

  useEffect(() => {
    if (filterName && !isEditFilterClicked) {
      let isRepeated = !!filterListV2.find(({ savedFilterName }: ISavedFilter)=> savedFilterName === filterName)
      setIsFilterNameDuplicated(isRepeated)
    }
  }, [filterName])

  let isSelected = selectedFilter && selectedFilter?.savedFilterGuid === selectedWorkbookV2?.savedFilter?.savedFilterGuid
  
  const onDelete = () => {
    const { savedFilterGuid } = selectedFilter || {}
    dispatch(deleteControlFilterV2({savedFilterGuid, isSelected, savedFilterName: selectedFilter.savedFilterName}))
    propsOnSave('')
    dispatch(setMainContent(''))
  }

  const onSave = () => {
    if(selectedFilter){
      const matchedReport = userReports?.find((report: any) => report.controlFilterGuid === selectedFilter.controlFilterGuid)
      matchedReport && setIsModalOpen(true)
      !matchedReport && updateControlFilter()
    }else{
      updateControlFilter()
    }
  }

  const updateControlFilter = () => {
    const filterGroup : IFiltersGroup = {
      workbookGuid: selectedWorkbookV2?.workbookGuid,
      savedFilterName: filterName,
      filters: getSelectedValues(workbookFilters!),
      isDefault: isDefault,
      isAutoSaving: false,
      sigmaControls: selectedWorkbookV2?.sigmaControls,
      isSelected: false
    }
    if(selectedFilter) {
      filterGroup.isSelected = isSelected
      filterGroup.savedFilterGuid = selectedFilter?.savedFilterGuid
      filterGroup.sigmaFilter = selectedFilter
      dispatch(saveWorkbookFilters(filterGroup))
    }else{
      dispatch(saveWorkbookFilters(filterGroup))
    }

    setFilterName('')
    propsOnSave('')
  }

  const compareByGroupFilterPosition = (a:IFilterLabel,b:IFilterLabel)=>a.groupFilterPosition-b.groupFilterPosition
  const compareByFitlerPosition = (a:IFilterLabel,b:IFilterLabel)=>a.filterPosition-b.filterPosition


  const sortAndJoinFilters = (filters:any) =>{
    const groupFilterPositions:any = []
    const orderedOwnFiltersByGroupPosition = filters.sort(compareByGroupFilterPosition)
    const filtersByGroup: any = []
    orderedOwnFiltersByGroupPosition.forEach((fe:IFilterLabel)=>!groupFilterPositions.includes(fe.groupFilterPosition) && groupFilterPositions.push(fe.groupFilterPosition))
    return({filtersOrdered:groupFilterPositions.map((gfp:number)=>orderedOwnFiltersByGroupPosition.filter((ogfp:IFilterLabel)=>ogfp.groupFilterPosition === gfp)).map((g:any)=>g.sort(compareByFitlerPosition)),groupFilterPositions})
  }

  const renderSelectionDetail = () => {
    const labels: any = ['General']
    const selectionDetails: any = []
    if(selectedWorkbookV2 && selectedWorkbookV2.sigmaControls){
      selectedWorkbookV2.sigmaControls.forEach(({sigmaControlId, values}: ISigmaControl)=>{
        const { sigmaControlId: filterSigmaCtrlId, groupFilterName, ...rest  } = workbookFiltersLabels?.find(({sigmaControlId: filterSigmaCtrlId}:IFilterLabel)=>{
          return filterSigmaCtrlId.toUpperCase() === sigmaControlId.toUpperCase()
        }) || {}
        selectionDetails.push({
          sigmaControlId: filterSigmaCtrlId,
          ...rest,
          values
        })
      })
    }
    if(workbookFilters && workbookFilters.length > 0){
      const filters = getSelectedValues(workbookFilters)
      workbookFilters.forEach((wf: IFilter)=>{
        const { filterValuesSelected }  = filters.find((f: ISelectedFilters)=>wf.filterSettingGuid === f.filterSettingGuid)!
        const { filterDisplayName, groupFilterPosition, filterPosition } = wf
        selectionDetails.push({ filterDisplayName,  filterPosition, groupFilterPosition, values: filterValuesSelected.map(({value}:IFilterValueSelected)=>value).toString()})
      })
    }
    const {filtersOrdered, groupFilterPositions} = sortAndJoinFilters(selectionDetails) 
    const filtersByGroup: any = []
    filtersOrdered.forEach((e:any,index:number)=>filtersByGroup.push(...e))

    const list = filtersByGroup.map(({filterDisplayName,sigmaControlId,values}:IFilterLabel,index:number)=>{
      return(
        <Fragment key={`${sigmaControlId}`}>
          {
            filterDisplayName && <Container className={`control-list-element`}>
              <Container className={'label'}>
                <Text textStyle='support'>{filterDisplayName}</Text>
              </Container>
              <Container className={'control-values'}>
                {
                    values?.split(',').map((value:string)=>{
                      return (
                        <Text>{`${value.replaceAll('%20',' ').replaceAll('%2C',',').replaceAll('%2F','/') || 'All' }`}</Text>
                      )
                    })
                }
              </Container>
            </Container>
          }
        </Fragment>
      )
    })
    return(
      <Container className={`${visibility} control-list`}>
        <Container className={`control-list-element`}>
          <Container className={'label'}>
            <Text textStyle='support'>{`Dashboard`}</Text>
          </Container>
          <Container className={'workbook-title'}>
            <Text>
              {selectedWorkbookV2.workbookDisplayName}
            </Text>
          </Container>
        </Container>
        {list}
      </Container>
    )
  }

  if (deleteConfirmation && selectedFilter) {
    return (
      <StyledWorkbookFilterSave className={`workbook-preset-save`}>
        <Text>{`Proceed to delete ${selectedFilter?.name}?`}</Text>
        <Container className={'actions-section'}>
          <Container className={'action-buttons'}>
            <Button
              color='error'
              onClick={onDelete}
            >
              <Text>
                {deleteFilterLabel}
              </Text>
            </Button>
            <Button
              onClick={() => setDeleteConfirmation(false)}
            >
              <Text>
                {goBackLabel}
              </Text>
            </Button>
          </Container>
        </Container>
      </StyledWorkbookFilterSave>
    )
  }

  if (isModalOpen) {
    return (
      <Modal
        isOpen={isModalOpen}
        requestClose={() => {
          setIsModalOpen(false)
        }}
      >
        <Container className={'preset-save-modal'}>
          <Container className={'preset-save-modal-title'}>
            <Container className={'separator'} />
            <Text textStyle='h1'>
              {
                ConfirmationTitle
              }
            </Text>
            <Button onClick={()=>setIsModalOpen(false)}>
              <IoMdClose size={'42px'} color={`${getColor('brandDark')}`} />
            </Button>
          </Container>
          <Container className={'action-section'}>
            <Container className='suggestion'>
              <Text color='suggestion'>{confirmationMessage}</Text>
              <Text color='suggestion'>{confirmationQuestionMessage}</Text>
            </Container>
            <Container className={'action-buttons'}>
              <Button onClick={() => setIsModalOpen(false)}><Text>{cancelLabel}</Text></Button>
              <Button onClick={() => { setIsModalOpen(false); setSelectedOption('yes'); }}><Text>{confirmationButtonLabel}</Text></Button>
            </Container>
          </Container>
        </Container>
      </Modal>
    )
  }
  return (
    <StyledWorkbookFilterSave className={`workbook-preset-save`}>
      {isFilterListLimitReached
        ? (
          <Container className={'error-message'}>
            <Text
              color='error'
            >
              {filtersSavedLimitMessage}
            </Text>
          </Container>
        ) : (
          <Container className={'filter-name-input'}>
            <TextInput
              color={`text`}
              value={filterName}
              onChange={({ target: { value } }: any)=>{/^[^\s]+.*$/.test(value) ? setFilterName(value) : setFilterName(''); setIsEditFilterClicked(false)}}
              label={filterNameLabel}
            />
          </Container>
        )
      }
      { isFilterNameDuplicated
        && (
          <Container className={'error-message'}>
            <Text color='error'>{filterNameDuplicatedMessage}</Text>
          </Container>
        )
      }
      <Container className={'filter-details-trigger'} onClick={() => setVisibility(visibility === 'visible' ? 'hidden' : 'visible')}>
        <Text textStyle='h5' color='brandDark'>
          {filterDetailsLabel}
        </Text>
        <MdOutlineKeyboardArrowDown className={visibility} size={24} />
      </Container>
      {
        renderSelectionDetail()
      }
      <Container className={'actions-section'}>
        <CheckboxInput
          value={isDefault}
          onChange={setIsDefault}
          isDisabled={isFilterListLimitReached}
        >
          {
            isDefault
              ? <Text>{defaultFilterLabel}</Text>
              : <Text>{makeDefaultFilterLabel}</Text>
          }
        </CheckboxInput>
        <Container className={'action-buttons'}>
          {selectedFilter && (
            <Button onClick={onDelete}>
              <Text>
                {deleteFilterLabel}
              </Text>
            </Button>
          )}
          <Button
            tooltipLabel={
              !filterName
                ? filterNameIsBlankMessage
                : undefined
            }
            disabled={isFilterNameDuplicated || !filterName}
            onClick={onSave}
          >
            <Text>
              {saveLabel}
            </Text>
          </Button>
        </Container>
      </Container>
    </StyledWorkbookFilterSave>
  )
}
export default WorkbookFilterSave