import { IFilter, IFilterValue, ISelectedFilters, ISetting, IDatePickerDefaultValues  } from 'utils/constants/interfaces'
import { filtersType } from 'utils/constants/index'
import {
  ACCESS_TOKEN_COOKIE_SUFFIX,
  REFRESH_TOKEN_COOKIE_SUFFIX,
  ID_TOKEN_COOKIE_SUFFIX,
  USER_DATA_COOKIE_SUFFIX,
  USER_COOKIE_PREFIX
} from 'utils/constants/cookiesNames'
import { CrownIcon, DiamondIcon } from 'assets/icons'
import { eachDayOfInterval } from 'date-fns'

const isDownloadAllowed = (workbookFilters:IFilter[], userSettings: ISetting[]) => {
  const selectedFilters =  getSelectedValues(workbookFilters)
  const selectedStateFilter = selectedFilters.find((wfs: ISelectedFilters)=> wfs.filterName === filtersType.State) 
  const stateValue = selectedStateFilter?.filterValuesSelected[0].id
  return userSettings.find((us:ISetting)=>us.state === stateValue)?.downloadAllowed
}
const getSelectedValues = (workbookFilters:IFilter[])=>{
  return workbookFilters?.map((wf:IFilter):ISelectedFilters=>{
      const filterValuesSelected = wf.filterValues.filter((fv:IFilterValue)=>fv.isSelected).map(({isSelected, ...rest}:IFilterValue)=>rest)
      const { filterSettingGuid, filterName, order } = wf
      return { filterSettingGuid, filterName, filterValuesSelected, order}
  })
}

const replaceLast = (str:string, pattern:any, replacement:string) => {
  const match =
    typeof pattern === 'string'
      ? pattern
      : (str.match(new RegExp(pattern.source, 'g')) || []).slice(-1)[0];
  if (!match) return str;
  const last = str.lastIndexOf(match);
  return last !== -1
    ? `${str.slice(0, last)}${replacement}${str.slice(last + match.length)}`
    : str;
};

const cookifyObject = (object: any) => {
  return window.btoa(JSON.stringify(object))
}

const cookifyString = (cookieValue: string) => {
  return window.btoa(cookieValue);
}


const getUserCookieName = () => `${process.env.REACT_APP_ENVIRONMENT}`.includes('prod') ? USER_COOKIE_PREFIX : `${USER_COOKIE_PREFIX}${process.env.REACT_APP_ENVIRONMENT}`;
const getUserCookieParametersConfig : any = () => ({
  path: '/',
  domain:
    window.location.host === 'localhost:3200'
      ? undefined
      : 'pistildata.com',
  sameSite: 'none',
  secure: true,
});

const cookieLabel = getUserCookieName();

const getSubCookieNames = () => ({
  idTokenLabel: `${cookieLabel}${ID_TOKEN_COOKIE_SUFFIX}`,
  accessTokenLabel: `${cookieLabel}${ACCESS_TOKEN_COOKIE_SUFFIX}`,
  refreshTokenLabel: `${cookieLabel}${REFRESH_TOKEN_COOKIE_SUFFIX}`,
  userData: `${cookieLabel}${USER_DATA_COOKIE_SUFFIX}`
});

const subCookieNames = getSubCookieNames();

const cookiesArray:any = document.cookie.split(';').reduce((acc, curr) => {
  const [key, value] = curr.replaceAll('"', '').replaceAll(' ', '').split('=')
  return ({
      ...acc, [key]: value,
  })
},{})

const base64Cookie = cookiesArray[cookieLabel]?.replaceAll('%3D', '=')
const base64idTokenCookie = cookiesArray[subCookieNames.idTokenLabel]?.replaceAll('%3D', '=');
const base64accessTokenCookie = cookiesArray[subCookieNames.accessTokenLabel]?.replaceAll('%3D', '=');
const base64refreshTokenCookie = cookiesArray[subCookieNames.refreshTokenLabel]?.replaceAll('%3D', '=');
const base64DataCookie = cookiesArray[subCookieNames.userData]?.replaceAll('%3D', '=');

const getUserFromSubcookies = () => {
  if (cookiesArray && base64idTokenCookie && base64accessTokenCookie && base64refreshTokenCookie && base64DataCookie) {
    return {
      id_token: atob(base64idTokenCookie),
      access_token: atob(base64accessTokenCookie),
      refresh_token: atob(base64refreshTokenCookie),
      ...JSON.parse(atob(base64DataCookie))
    };
  
  }
  if(cookiesArray && base64Cookie) {
    const { id_token, access_token, refresh_token, ...userData } = JSON.parse(window.atob(`${base64Cookie}`));

    return {
      id_token: id_token,
      access_token: access_token,
      refresh_token: refresh_token,
      ... userData
    }
  }
}
const toTimeDecimal = (str: string) => {
  const number = Number(str)
  if (number < 10) {
    return `0${str}`
  }
  return str
}

const subtractMonths = (date: Date, months: number)=>{
  let d = new Date(date)
  const monthId = d.getMonth()
  const day = d.getDate();
  d.setMonth(monthId - months)
  if(d.getDate() < day){
    d.setDate(0)
  }
  return d
}

const getWeekdayString = (str: string) => {
  const days: any = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturdat',
  }

  return days[str]
}


const getHourFromCronExpression = (cronExp:string) => {
  const coronElements = cronExp.split(' ')
  return `${coronElements[1]}:${toTimeDecimal(coronElements[0])}`
}

const getOnDayFromCronExpression = (cronExp:string) => {
  const coronElements = cronExp.split(' ')
  return (coronElements[2])
}


const getRepeatDayFromCronExpression = (cronExp:string) => {
  const coronElements = cronExp.split(' ')
  return (coronElements[4])
}


const getFrequencyFromCronExpression = (cronExp:string) => {
  const coronElements = cronExp.split(' ')
  const isDaily = coronElements[2] === '*' && coronElements[3] === '*' && coronElements[4] === '*' 
  const isWeekly = coronElements[2] === '*' && coronElements[3] === '*' && coronElements[4] !== '*' 
  const isMonthly = coronElements[2] !== '*' && coronElements[3] === '*' && coronElements[4] === '*' 
  const string = cronExp
  if (isDaily) {
    return {string: `daily`, index: 0}
  }
  if (isWeekly) {
    return {string: `weekly`, index: 1}
  }
  if (isMonthly) {
    return {string: `monthly`, index: 2}
  }
  return (string)
}
 
const getStringFromCronExpression = (cronExp:string) => {
  const coronElements = cronExp.split(' ')
  const isDaily = coronElements[2] === '*' && coronElements[3] === '*' && coronElements[4] === '*' 
  const isWeekly = coronElements[2] === '*' && coronElements[3] === '*' && coronElements[4] !== '*' 
  const isMonthly = coronElements[2] !== '*' && coronElements[3] === '*' && coronElements[4] === '*' 
  const string = cronExp
  const period = Number(coronElements[1]) > 12 ? "pm" : "am"
  const hour = String(Number(coronElements[1]) % 12 || 12)
  if (isDaily) {
    
    return `Daily at ${hour}:${toTimeDecimal(coronElements[0])} ${period}`
  }
  if (isWeekly) {
    return `Every ${getWeekdayString(coronElements[4])} at ${hour}:${toTimeDecimal(coronElements[0])}`
  }
  if (isMonthly) {
    return `Monthly, day ${coronElements[2]} at ${hour}:${toTimeDecimal(coronElements[0])}`
  }
  return (string)
}


const getWorkbookTierIcon = (groupTier: string, subscriptionTier:string|undefined) => {
  let icon
  switch(groupTier){
    case subscriptionTier:
      icon = undefined
    break
    case "STANDARD":
      icon = CrownIcon
    break;
    case "PREMIUM":
      icon = DiamondIcon
    break;
    default:
      icon = undefined
  }
  return icon
}

const sliceLabel = (label:string) => {
  if(label.includes('0')){
    const labelLenght = label.length
    return label.slice(0,labelLenght - 1)
  }else{
    return label
  }
}

const generateDisabledDates = (start: Date, end: Date) => {
  return  eachDayOfInterval({
      start: new Date(start),
      end: new Date(end)
  })
}

const getDatePickerDefaultValues = (): IDatePickerDefaultValues=>{
  const monthsLimit = 3
  const today = new Date()
  const currentMonth = today.getMonth()
  const startDate = new Date(structuredClone(today).setMonth(currentMonth - 6))
  const endDate = new Date(structuredClone(today).setMonth(currentMonth - monthsLimit))
  const disabledDates = generateDisabledDates(startDate, endDate)
  const endDateAfterToday = new Date(structuredClone(today).setMonth(currentMonth + monthsLimit))
  const disabledDaysAfterToday = generateDisabledDates(today, endDateAfterToday)
  disabledDaysAfterToday.map(( e: Date ) => {
    disabledDates.push(e)
    return undefined
  })
  const minDate = new Date(structuredClone(today).setMonth(currentMonth - monthsLimit))
  const maxDate = new Date(structuredClone(today).setMonth(currentMonth))
  const shownDate = new Date(today.setMonth(currentMonth -1))
  return { minDate: minDate, maxDate: maxDate, shownDate: shownDate, disabledDates: disabledDates} as IDatePickerDefaultValues
}

const formatDate = (date: Date, withDays: boolean, withYears: boolean, separatorChar: string) => {
  const month = date.getMonth() + 1 
  const days = date.getDate()
  const year = date.getFullYear()
  const paddedMonth = toTimeDecimal(month.toString())
  const paddedDays = toTimeDecimal(days.toString())
  if( !withDays ){
      return (`${paddedMonth}${separatorChar}${year}`)
  }
  if(!withYears){
      return (`${paddedDays}${separatorChar}${paddedMonth}`)
  }
  return (`${paddedMonth}${separatorChar}${paddedDays}${separatorChar}${year}`)

}

export {
  cookifyObject,
  cookifyString,
  replaceLast,
  getUserCookieName,
  getSubCookieNames,
  getUserCookieParametersConfig,
  getUserFromSubcookies,
  getStringFromCronExpression,
  getFrequencyFromCronExpression,
  getHourFromCronExpression,
  getOnDayFromCronExpression,
  getRepeatDayFromCronExpression,
  getSelectedValues,
  isDownloadAllowed,
  getWorkbookTierIcon,
  toTimeDecimal,
  subtractMonths,
  sliceLabel,
  generateDisabledDates,
  getDatePickerDefaultValues,
  formatDate
}