import styled from "styled-components"
import { Container } from "../../containers"
import {getColor} from 'utils'

const StyledDropdownActions = styled(Container)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 4px;
    min-width: 250px;
    width: 100%;
    height: 32px;
    border-bottom: 1px solid ${getColor('sigmaBorder')};
    align-content: center;
    font-family: 'GilroyRegular';
    .loader{
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 3px;
        background: ${getColor('brandClear')};
        opacity: 0;
        z-index: 1;
        div{
            background: ${getColor('brandDark')};
            height: 100%;
            width: 0px;
            transition: width 0ms ease 0s;
        }
    }
    .dropdown-action-search-container{
        padding: 4px 0;
        padding-right: 4px;
        width: 100%;
        justify-content: flex-start;
        input{
            height: 20px;
            border: 0px;
            padding: 3px;
            background: ${getColor('sigmaColorsBackground')};
            max-height: 30px;
            outline: none;
            font-family: inherit;
            color: ${getColor('text')};
            width: 100%;
            ::placeholder{
                color: #B6B2C5;
            }
        }
    }
    .x-icon{
        position: relative;
        border: 0px;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        color: ${getColor('brand')};
        background-color: transparent;
        cursor: pointer;
        width: auto;
        padding: 5px;
        &.hidden{
            opacity: 0;
        }
        .visible{
            opacity: 1;
        }
        
    }
    .sort-by-container{
        position: absolute;
        will-change: transform;
        top: 6px;
        right: 55px;
        transform: translate3d(56px, 26px,0px);
        border-radius: 2px;
        background-color: ${getColor('sigmaColorsBackground')};
        color: ${getColor('text')};
        font-family: 'GilroyRegular';
        border: solid 1px ${getColor('sigmaBorder')};
        box-shadow: rgba(22,22,22,0.2) 0px 4px 9px --2px;
        z-index: 1030;
        flex-flow: column nowrap;
        justify-content: start;
        &.visible-container{
            display: flex;
        }
        &.hidden-container{
            display: none;
        }
        .sort-by-title{
            font-size: 11px;
            text-transform: uppercase;
            font-family: 'GilroyRegular';
            font-weight: 600;
            text-align: start;
            width: 100%;
            padding-left: 8px;
            padding-right: 8px;
            letter-spacing: .24px;
            line-height: 16px;
            color: ${getColor('brand')};
        }
    }

`

export default StyledDropdownActions