const toLabel = "To";
const subjectLabel = "Subject";
const emailBodyLabel = "Email Body"
const dashboardLabel = "Dashboard"
const availableStatesTerritories = "Available States / Territories"
const availableCategories = "Available Categories"
const filtersLabel = "Filters"
const yourFiltersLabel = "Your Filters"
const filterNameLabel = "Filter Name"
const filterDetailsLabel = "Filter Details"
const defaultFilterLabel = "Default Filter"
const makeDefaultFilterLabel = "Make this my default filter"
const formatAsLabel = "Format As"
const repeatByLabel = "Repeat By"
const timeZoneLabel = "Timezone"
const selectDayLabel = "Select a day"
const timeLabel = "Time"
const cancelLabel = "CANCEL"
const deleteLabel = "DELETE"
const deleteFilterLabel = "DELETE FILTER"
const sendLabel = "SEND"
const saveLabel = "SAVE"
const saveFilterLabel = "Save Filter"
const goBackLabel = "GO BACK"
const newScheduleReportLabel = "NEW SCHEDULED REPORT"
const createScheduleLabel = "SCHEDULE REPORT"
const updateScheduleLabel = "UPDATE SCHEDULE"
const emailFormatMessage = "This is a valid field value (which can be separated by commas):"
const emailListExample = "user@pistildata.com, user2@pistildata.com"
const downloadErrorMessage = "Download failed"
const filterWarningMessage = "Cannot delete current filter because it is being used in scheduled send."
const filterActionSuggestion = "Please delete or edit the scheduled export associated to this filter and try again."
const filtersSavedLimitMessage = "You can only have a max of 20 Saved Filters"
const filterNameDuplicatedMessage = "Duplicate Filter name found. Please enter a unique Filter Name to save your filters"
const filterNameIsBlankMessage = "Filter name is blank. Please enter a filter name to save your filters"
const confirmationMessage = "This filter is associated with an existing scheduled send."
const confirmationQuestionMessage = "Are you sure you want to update filter?"
const confirmationButtonLabel = "YES"
const okLabel = "OK"
const sidebarFiltersActionLabel = "Save, View, Send or Download your Filter Search Data"
const getPreviewTitle =  (workbookName:string) => `Upgrade to Standard Version to See the Full ${workbookName || 'Workbook'}`
const upgradeNow = "Upgrade Now"
const filterTooltipLabel = "This filter will be disabled if values are selected for the County or Store filters."

export {
    toLabel,
    subjectLabel,
    emailBodyLabel,
    dashboardLabel,
    availableStatesTerritories,
    availableCategories,
    filtersLabel,
    yourFiltersLabel,
    filterNameLabel,
    filterDetailsLabel,
    defaultFilterLabel,
    formatAsLabel,
    repeatByLabel,
    timeZoneLabel,
    selectDayLabel,
    timeLabel,
    cancelLabel,
    deleteLabel,
    deleteFilterLabel,
    makeDefaultFilterLabel,
    sendLabel,
    saveLabel,
    saveFilterLabel,
    goBackLabel,
    newScheduleReportLabel,
    createScheduleLabel,
    updateScheduleLabel,
    emailFormatMessage,
    emailListExample,
    filterWarningMessage,
    filterActionSuggestion,
    filtersSavedLimitMessage,
    filterNameDuplicatedMessage,
    filterNameIsBlankMessage,
    confirmationMessage,
    confirmationQuestionMessage,
    confirmationButtonLabel,
    okLabel,
    sidebarFiltersActionLabel,
    downloadErrorMessage,
    getPreviewTitle,
    upgradeNow,
    filterTooltipLabel
}