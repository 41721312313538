import { IAppNames, IAllowedRoles, IInitialValues } from './interfaces'

export * from './labels'
export * from './placeholders'
export * from './titles'

export const appNames: IAppNames = {
 insights: 'insights',
}

export const fieldsInitialValues = {
    recipients: '',
    subject: '',
    message: '',
    body: '',
    dashBoardItems: []
} as IInitialValues

export const allowedRoles: IAllowedRoles = {
    insights: 'PistilInsights'
}

export const filtersType = {
    Country: "COUNTRY",
    State: "STATE"
}

const insightsHosts = [
    'staging.insights.pistildata.com',
    'dev.insights.pistildata.com'
]
const reactAppEnv = process.env["REACT_APP_ENVIRONMENT"]
const currentHost = window.location.host

export const isInsights = reactAppEnv === 'prod-insights' || insightsHosts.includes(currentHost)

export const premiumSub = 'PREMIUM'

export const filtersLabels = ['COUNTY','PISTIL_RETAILER_ID','CONSUMER_AGE','CONSUMER_GENDER'] 
