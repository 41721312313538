import styled from 'styled-components'
import { getColor } from 'utils'
import { Container } from 'components'
import { devices } from 'utils/constants/media-queries'


const StyledWorkbookActions = styled(Container)`
  width: 100%;
  background: rgb(251, 250, 255);
  padding-right: 16px;
  justify-content: flex-end;
  .action-list-container {
    justify-content: flex-start;
    padding: 0 48px;
    flex: 1;
    padding-right: 32px;
    gap: 8px;
    height: 100%;
    justify-content: flex-start;
    align-items:flex-end;
    flex-direction: column-reverse;
    padding: 0 44px; 
    .right-side-container {
      height: 100%;
      gap: 16px;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      .filters-container{
        flex-flow: row wrap;
        gap: 10px;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        .batch-container{
          flex-direction: column;
          gap: 16px;
          flex-wrap: wrap;
          width: calc(100% - 52% + 1px);
          >p{
            font-size: 36px;
            font-weight: 600;
          }
          &.batch-100-container{
            width: 100%;
          }
          &.border-right-container{
            border-right: 4px solid #d7d4e1;
            &:last-child{
              border: none;
            }
          }
          .batch-group-container{
            gap: 16px;
            width: 100%;
            justify-content: flex-start;
            flex-wrap: wrap;
            .dropdown-menu-container{
              min-width: 110px;
              .dropdown-selector-container{
                min-width: auto;
                .dropdown-container{
                  .trigger-container{
                    position: relative;
                    .label-container{
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: calc(100% - 40px);
                    }
                    .simple-container{
                      position: absolute;
                      right: 7px;
                    }
                  }
                  .dropdown-body-container{
                      .dropdown-actions-container{
                        min-width: auto;
                      }
                  } 
                }
              }
            }
          }
        }
        .row-container{
          flex-direction: column;
          width: 100%;
          gap: 16px;
          flex-wrap: wrap;
        }
      }
      .dropdown-menu-container {
        gap: 4px;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        &.sm-container{
          max-width: 17%;
        }
        p.h4 {
          font-size: 14px;
          font-weight: 600;
        }
        .dropdown-container {
          justify-content: flex-start;
        }
        .dropdown-selector-container {
          min-width: 184px;
          flex: 1;
          border-radius: 20px;
          .dropdown-container {
            height: unset;
            .trigger-container {
              justify-content: space-between;
              height: unset;
              align-items: base-line;
              /* New */
              width: 100%;
            }
            .dropdown-body-container{
              width: 100%;
            }
          }
          .label-container {
          }
          .simple-container{
            height: 10px;
            button.clear{
              transition: none;
              height: 16px;
              width: 16px;
              padding: 0;
              flex-wrap: wrap;
              align-content: center;
              border-radius: 0;
              margin-right: 5px;
              &:hover{
                background-color: #EAE8F1;
              }          
            }
          }
        }
      }
    }
    button.action-button {
      height: 34px;
      padding: 0 10px;
      align-items: center;
      gap: 4px;
      min-width: 120px;
      border-radius: 50px;
      border: 1px solid rgba(20, 105, 119, 0.23);
      color: ${getColor('brandDark')};
      p {
        font-size: 14px;
        color: ${getColor('brandDark')};
        font-weight: 400;
      }
      img {
        height: unset;
        width: unset;
      }
    }
    .left-side-container {
      align-items: flex-end;
      gap: 16px;
      &.top-container{
        .filter-actions-container{
          button{
            :first-child{
              display: none;
            }
          }
        }
        .send-container{
            display: none;
        }
        .simple-container button{
          :last-child{
            display: none;
          }
        }
      }
      &.enabled{
        display: flex;
      }
      &.disabled{
        display: none;
      }
      .changes-indicator-container {
        p {
          font-weight: 400;
        }
      }
      .filter-actions-container {
        gap: 16px;
        justify-content: center;
        width: 100%;
        button {
          width: 150px;
          min-width: 150px;
          max-width: 200px;
          :first-child{
            display: none;
          }
        }
        p {
          max-width: calc(100% - 35px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .tooltip-container {
          p {
            color: ${getColor('white')};
          }
        }
      }
      .send-container {
        display: none;
        border-radius: 50px;
        border: 1px solid ${getColor('brandDark')}3b;
        color: ${getColor('brandDark')};
        p {
          color: ${getColor('brandDark')};
          font-weight: 400;
          white-space: nowrap;
        }
        .trigger-container {
          gap: 8px;
        }
      }
      .simple-container button{
          :last-child{
            display: none;
          }
      }
    }
  }
  .modal-content-container {
    height: 100%;
    width: 100%;
    padding: 0;
  }
  .workbook-actions-modal-container {
    flex-direction: column;
    width: 100%;
    max-height: 95vh;
    .workbook-actions-modal-title-container {
      padding: 24px 36px;
      padding-bottom: 16px; 
      justify-content: center;
      width: 100%;
      border-bottom: 1px solid ${getColor('brandDark')};
      p.h1 {
        color: ${getColor('brandDark')};
        text-align: center;
      }
      .separator-container {
        width: 32px;
        display: none;
      }
      button {
        width: 32px;
        height: 32px;
        padding: unset;
        justify-content: center;
        align-items: center;
        &:nth-of-type(2){
          opacity: 0;
          display: none;
        }        
      }
      .arrow-button{
        position: absolute;
        left: 30px;
      }
    }
    .workbook-download-container{
      flex-direction:column;
      gap:16px;
      -webkit-box-pack:start;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-y: auto;
      padding: 18px 18px 18px 36px;
      .download-list-container{
        display: inline-block;
        overflow-y: auto;
        max-height: 60vh;
      }
    }
    .workbook-export-container{ 
      .row-container{
        overflow-y: auto;
        padding-right: 18px;
      }
    }
    .action-section-container{
      display: flex;
      flex-flow: column wrap;
      max-width: 75%;
      padding: 30px;
      .suggestion-container{
        display: flex;
        flex-flow: column wrap;
        gap: 10px;
        p{
          font-family: "GilroyMedium";
          padding: 10px;
          text-align: center;
          color: ${getColor('suggestion')};
        }
      }
      .action-buttons-container{
        gap: 16px;
        width: 100%;
        justify-content: flex-start;
        padding-top: 16px;
        button {
          flex: 1;
          height: 56px;
          border-radius: 30px;
          transition: all ease-in-out .3s;
          background: transparent;
          justify-content: center;
          :nth-of-type(1) {
            border: 1px solid ${getColor('brandDark')};
            p {
              color: ${getColor('brandDark')};
            }
          }
          &.delete {
            flex: 0.5;
            border: 1px solid ${getColor('error')};
            background: ${getColor('error')};
            p {
              color: ${getColor('white')};
            }
          }
          :nth-last-of-type(1) {
            border: 1px solid ${getColor('brand')};
            background: ${getColor('brand')};
            p {
              color: ${getColor('white')};
            }
          }
        }
      }
    }
  }
  .preset-save-modal-container{
    flex-direction: column;
    width: 100%;
    max-height: 95vh;
    .preset-save-modal-title-container{
      border-bottom: 1px solid ${getColor('border')};
      padding: 24px 36px;
      padding-bottom: 16px; 
      justify-content: space-between;
      width: 100%;
      p.h1 {
        color: ${getColor('brandDark')};
      }
      .separator-container{
        width: 32px;
      }
      button{
        width: 32px;
        height: 32px;
        padding: unset;
        justify-content: center;
        align-items: center;
      }
    }
    .action-section-container{
      display: flex;
      flex-flow: column wrap;
      max-width: 75%;
      padding: 30px;
      .suggestion-container{
        display: flex;
        flex-flow: column wrap;
        gap: 10px;
        p{
          font-family: "GilroyMedium";
          padding: 10px;
          text-align: center;
          color: ${getColor('suggestion')};
        }
      }
      .action-buttons-container{
        gap: 16px;
        width: 100%;
        justify-content: flex-start;
        padding-top: 16px;
        button {
          flex: 1;
          height: 56px;
          border-radius: 30px;
          transition: all ease-in-out .3s;
          background: transparent;
          justify-content: center;
          :nth-of-type(1) {
            border: 1px solid ${getColor('brandDark')};
            p {
              color: ${getColor('brandDark')};
            }
          }
          &.delete {
            flex: 0.5;
            border: 1px solid ${getColor('error')};
            background: ${getColor('error')};
            p {
              color: ${getColor('white')};
            }
          }
          :nth-last-of-type(1) {
            border: 1px solid ${getColor('brand')};
            background: ${getColor('brand')};
            p {
              color: ${getColor('white')};
            }
          }
        }
      }
    }
  }
  button {
    background: transparent;
  }

  @media only screen and ${devices.tablet}{
    .action-list-container{
      flex-direction: column-reverse;
      .right-side-container{
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
        .dropdown-menu-container{
          max-width: 20%;
          min-width: fit-content;
        }
        .filters-container{
          flex-direction: row;
          align-content: flex-start;
          width: 100%;
          gap: 16px;
          .batch-container{
            width: calc(100% - 52% + 1px);
            .batch-group-container{
              .dropdown-menu-container{
                min-width: 110px;
                .dropdown-selector-container{
                  min-width: auto;
                  .dropdown-container{
                    .trigger-container{
                      position: relative;
                      .label-container{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: auto;
                        max-width: calc(100% - 25px);
                      }
                      .simple-container{
                        position: absolute;
                        right: 7px;
                      }
                    }
                    .dropdown-body-container{
                        .dropdown-actions-container{
                          min-width: auto;
                        }
                    } 
                  }
                }
              }
            }
          }

          .row-container{
            gap:16px;
            width: 100%;
            justify-content: flex-start;
            flex-direction: row;
          }
        }
        .filter-actions-container { 
          width: auto;
          position: absolute;
          top: 62px;
          right: 300px;
          button {
            width: auto;
            :first-child{
              display: flex;
            }
          }
        }
      }
      .left-side-container { 
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 21px;
        &.top-container{
          position: absolute;
          top: 0;
          right: 48px;
          padding-top: 25px;
          .filter-actions-container{
            button{
              :first-child{
                display: flex;
              }
            }
          }
          .send-container{
            display: flex;
          }
          .simple-container button{
            :last-child{
              display: flex;
            }
          }
        }
        &.enabled{
          display: flex;
        }
        &.disabled{
          display: none;
        }
        .send-container{
          display: flex;
        }
        .simple-container button{
          :last-child{
            display: flex;
          }
        }
        .filter-actions-container{
          button {
            width: 150px;
            min-width: 150px;
            max-width: 200px;
            :first-child{
              display: flex;
            }
          }
        }
      }
    }
    .workbook-actions-modal-container {
      .workbook-actions-modal-title-container {
          .separator-container {
            width: 32px;
          }
          justify-content: space-between;
          border-bottom: 1px solid ${getColor('border')};
          button{
              opacity: 1;
              &:nth-of-type(2){
                opacity: 1;
                display: flex;
              }
          }
          .arrow-button{
                position: relative;
                opacity: 0;
          } 
      }
    }
    .modal-content-container{
      height: auto;
      width: calc(100% - 32px);
      max-width: 660px;
    }
    
  }
  @media only screen and ${devices.laptop}{
    .action-list-container{
      flex-direction: row;
      .right-side-container{
        .filters-container{
          .batch-container{
            width: calc(100% - 52% + 2px);
            .batch-group-container{
              :first-child{
                  .dropdown-menu-container{
                    :last-child{
                      min-width: 165px;
                    }
                  }
              }
              .dropdown-menu-container{
                min-width: 110px;
                .dropdown-selector-container{
                  min-width: auto;
                  .dropdown-container{
                    .trigger-container{
                      position: relative;
                      .label-container{
                          overflow: hidden;
                          text-overflow: ellipsis;
                          width: auto;
                          max-width: calc(100% - 30px);
                      }
                      .simple-container{
                        position: absolute;
                        right: 7px;
                      }
                    }
                    .dropdown-body-container{
                        .dropdown-actions-container{
                          min-width: auto;
                        }
                    } 
                  }
                }
              }
          }
          }

        }
      }
    }
  }
  @media only screen and (min-width: 1040px){
    .action-list-container{
      flex-direction: row;
      .right-side-container{
        .filters-container{
          .batch-container{
            width: calc(100% - 52% + 2px);
          }

        }
      }
    }
  }
  @media only screen and ${devices.desktop}{
    .action-list-container{
      flex-direction: row;
      .right-side-container{
        .filters-container{
          .batch-container{
            width: calc(100% - 52% + 2px);
            .batch-group-container{
              .dropdown-menu-container{
                min-width: 141px;
                .dropdown-selector-container{
                  min-width: auto;
                  .dropdown-container{
                    .trigger-container{
                      position: relative;
                      .label-container{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: auto;
                        max-width: calc(100% - 30px);
                      }
                      .simple-container{
                        position: absolute;
                        right: 7px;
                      }
                    }
                    .dropdown-body-container{
                        .dropdown-actions-container{
                          min-width: auto;
                        }
                    } 
                  }
                }
              }
            }
          }

        }
      }
    }
    .workbook-actions-modal-container {
      .workbook-actions-modal-title-container {
          border-bottom: 1px solid ${getColor('border')};
      }
    }
  }

  @media only screen and (min-width: 1320px){
    .action-list-container{
      .right-side-container{
        .filters-container{
          .batch-container{
            width: calc(100% - 52% + 1px);
            .batch-group-container{
              .dropdown-menu-container{
                .dropdown-selector-container{
                  .dropdown-container{
                    .trigger-container{
                      .label-container{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: auto;
                        max-width: calc(100% - 30px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  @media only screen and ${devices['2xl']}{
    .action-list-container{
      .right-side-container{
        .filters-container{
          .batch-container{
            width: calc(100% - 52% + 1px);
            .batch-group-container{
              .dropdown-menu-container{
                .dropdown-selector-container{
                  .dropdown-container{
                    .trigger-container{
                      .label-container{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: auto;
                        max-width: calc(100% - 30px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 2000px){
    .action-list-container{
      .right-side-container{
        .filters-container{
          .batch-container{
            width: calc(100% - 52% + 1px);
            .batch-group-container{
              .dropdown-menu-container{
                width:500px;
                .dropdown-selector-container{
                  .dropdown-container{
                    .trigger-container{
                      .label-container{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: auto;
                        max-width: calc(100% - 50px);
                        p{
                          width: auto;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }
      }
    }
  }
  @media only screen and (min-width: 2720px){
    .action-list-container{
      .right-side-container{
        .filters-container{
          .batch-container{
            width: calc(100% - 52%);
            .batch-group-container{
              .dropdown-menu-container{
                width: 700px;
                .dropdown-selector-container{
                  .dropdown-container{
                    .trigger-container{
                      justify-content: flex-start;
                      .label-container{
                        max-width: calc(100% - 50px);
                        p{
                          width: auto;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }
      }
    }
  }

  @media only screen and (min-width: 3000px){
    .action-list-container{
      .right-side-container{
        .filters-container{
          .batch-container{
            width: calc(100% - 52% + 0px);
            .batch-group-container{
              .dropdown-menu-container{
                .dropdown-selector-container{
                  .dropdown-container{
                    .trigger-container{
                      justify-content: flex-start;
                      .label-container{
                        max-width: calc(100% - 50px);
                        p{
                          width: auto;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }
      }
    }
  }
`

export default StyledWorkbookActions
