import { hooks, mainOperations, mainSelectors, dataTrackingOperations } from 'state'
import { getSelectedValues } from 'utils';
import { useEffect, useRef, useState } from 'react';
import { IFiltersGroup, ISigmaControl } from 'utils/constants/interfaces'
import { eventsCategory, filterSelection } from '../../../utils/constants/events';
import StyledWorkbookViewer from './workbook-viewer-styled';
import { appNames } from '../../../utils/constants';
import { Spinner } from '../../shared';
import { LoadingSpinner } from '../../../assets';
import { useParams } from 'react-router-dom';

export const WorkbookViewer = () => {
  const [diff, setDiff] = useState<any>({})
  const { useAppSelector, useAppDispatch } = hooks
  const { selectmain } = mainSelectors
  const { saveWorkbookFilters, updateSelectedFilterSaved, updateDefaultFilterSaved } = mainOperations
  const { selectedWorkbookV2, workbookFilters, isFetching} = useAppSelector(selectmain)
  const { trackEvent } = dataTrackingOperations
  const params = useParams()
  const dispatch = useAppDispatch()
  const iframeRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    const hookToEvent = async (event: any) => {
      if (
        event.origin === "https://app.sigmacomputing.com"
      ) {
        const variableCount = Object.keys(event?.data?.workbook.variables).length
        if(variableCount > 0){
          setDiff({
            variables: event.data.workbook?.variables,
            eventType: event.data.type,
            hasChanges: event.data.type !== 'workbook:loaded'
          })
          await dispatch(updateDefaultFilterSaved(undefined))
        }
      }
    }
    if (iframeRef.current) {
      window.addEventListener('message', (ev: any) => hookToEvent(ev));
      return () => {
        window.removeEventListener('message', (ev: any) => hookToEvent(ev));
      };
    }
  }, [iframeRef.current])

  useEffect(() => {

    const asyncUpdateControlFilters = async (controlFiltersParams: any | undefined, filtersGroup: IFiltersGroup | undefined) => {
      await dispatch(saveWorkbookFilters(filtersGroup!))
      await dispatch(updateSelectedFilterSaved(undefined))
    }
    if(diff?.variables){
        const vars = [] as ISigmaControl[] 
        diff?.variables && Object.values(diff?.variables).forEach((v, index) => {
          vars.push({ sigmaControlId: Object.keys(diff?.variables)[index], values: `${v}` })
        })
        if (vars.length === 1) {
          dispatch(trackEvent({ eventCategory: eventsCategory.filter_selection, values: { workbookGuid: selectedWorkbookV2?.workbookGuid, filterName: vars[0].sigmaControlId, filterValues: vars[0].values }, event: filterSelection.sigma_filter_select }))
        }
        let filteredVars = undefined
        if (selectedWorkbookV2?.sigmaControls?.length > 0 && vars.length === 1) {
          filteredVars = selectedWorkbookV2?.sigmaControls.map(({ sigmaControlId }: ISigmaControl, index: number, array: ISigmaControl[]) => {
            if (sigmaControlId === vars[0].sigmaControlId) {
              return (vars[0])
            }
            return array[index]
          })
        } else{
          filteredVars = vars
        }
        if (vars.length > 0 && filteredVars?.length > 0) {
          const filtersGroup: IFiltersGroup = {
            workbookGuid: selectedWorkbookV2?.workbookGuid,
            filters: getSelectedValues(workbookFilters!),
            isDefault: false,
            isLastSelection: true,
            isAutoSaving: true,
            savedFilterName: null,
            sigmaControls: [...filteredVars],
          }
          asyncUpdateControlFilters(undefined, filtersGroup)
        }
    }
  }, [diff])

  if(isFetching ||  params.id !== selectedWorkbookV2?.workbookGuid){
    return(
      <Spinner text={"Loading..."} size='200'><LoadingSpinner/></Spinner> 
    )
  }
  return (
    <StyledWorkbookViewer className="workbook-viewer">
      {
        <iframe
          title={selectedWorkbookV2?.workbookDisplayName}
          ref={iframeRef}
          key={selectedWorkbookV2?.workbookGuid}
          src={selectedWorkbookV2?.workbookDashboards[0]?.embedUrl}
        >
        </iframe>
      }
    </StyledWorkbookViewer>
  )
}
export default WorkbookViewer